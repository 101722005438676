import React, { useEffect, useState } from 'react';
import { Typography, Row, Col, Spin } from 'antd';
// import RelatedBlog from './RelatedBlogs';
import { useMediaQuery } from '../../hooks/MediaQuery.hook';
import { styles } from '../../styles';
import { blogByTitle } from '../../apis/fixture.api';
import { useParams } from 'react-router-dom';
import { DEFAULT_TITLE } from '../../utils/constant';

const { Title, Paragraph } = Typography;

export default function BlogDetail() {
  const matches = useMediaQuery('(min-width: 768px)');

  const [blog, setBlog] = useState<any>();
  const [loader, setLoader] = useState<boolean>(false);
  const { title } = useParams();


  const getBlog = async ()=> {
    setLoader(true);
    const response = await blogByTitle(title);
    setBlog(response.data);
    setLoader(false);
  }

  useEffect(() => {
    document.title = title ?? DEFAULT_TITLE;
    getBlog();
  }, []);

  return (
    <Row justify="center">
      <Col xs={24} sm={22} md={20} lg={18}>

        <div style={styles.blogContainer}>  
          <Typography>
          <p style={styles.blogCategory}>{blog?.datetime ? new Intl.DateTimeFormat('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: true
            }).format(new Date(blog?.datetime)):''}</p>
            <p style={{...styles.blogCategory, marginTop: '0px'}}>{blog?.category}</p>
            <Title level={1} style={{...styles.mainHeading, fontSize: !matches ? '24px': ''}}>
                {blog?.title}
            </Title>
            <Paragraph style={styles.blogParagraph}>
                {blog?.teaser}
            </Paragraph>

            {/* <div style={{textAlign: 'center'}}>
                <Image
                preview={false}
                src={blog?.image}
                alt={blog?.oldTitle}
                style={{ borderRadius: '8px', width: matches ? '100%' : '95%', margin: '10px' }}
                />
            </div> */}

            <Paragraph style={styles.blogParagraph}>
                {blog?.title2}
            </Paragraph>

            {blog?.content?.map((item: any, index: number) => {
                  return (
                    <div key={index} className="container-fluid">
                      <Title level={2} style={styles.blogHeading}>{item?.heading}</Title>
                        <Paragraph style={styles.blogParagraph}>
                            {item?.description}
                        </Paragraph>
                    </div>
                  );
                })}
          </Typography>
        </div>
      </Col>
      <div style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 10,
                  }}>
                  {loader ? <Spin /> : <></>}
            </div>
    </Row>
  );
}
