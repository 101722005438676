import { Routes, Route } from 'react-router-dom';

import Layout, { Content, Header } from 'antd/es/layout/layout';
import MainDescription from './views/Description';
import { HeaderComponent } from './components/Header';
import { styles } from './styles';
import NotFoundPage from './views/Description/notFound';
import BlogDetail from './components/LandingPage/BlogDetail';
// import { CompetitionPage } from './components/LandingPage/CompetitionPage';
// import { Competition } from './components/LandingPage/CompetitionPage/Competitions/Competition/Competiton';
// import { Transfer } from './views/Transfers';

const Router = () => {
  return (
    <Layout className="mainLayout">
      <Header style={styles.header}>
        <HeaderComponent></HeaderComponent>
      </Header>
      <Content style={{marginTop: '3%'}}>
        <Routes>
          <Route
            path='/'
            element={
              <MainDescription />
            }
          />
          {/* <Route
            path='/competitions/:competitionName'
            element={
              <Competition />
            }
          /> */}
          {/* <Route
            path='/competitions'
            element={
              <CompetitionPage />
            }
          /> */}
          {/* <Route
            path='/transfers'
            element={
              <Transfer />
            }
          /> */}
          <Route
            path='/blogs/:title'
            element={
              <BlogDetail />
            }
          />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Content>
    </Layout>
  );
};

export default Router;
