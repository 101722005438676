import React from 'react';

import { 
  Row, 
  Col, 
  List, 
  Typography, 
  Divider
} from 'antd';
import { STREAMERS_DATA } from '../../utils/constant';
import { ExportOutlined } from '@ant-design/icons';
import { styles } from '../../styles';
import { useMediaQuery } from '../../hooks/MediaQuery.hook';

export const Feature = () => {
  const matches = useMediaQuery('(min-width: 768px)');
  const fontSize = matches ? 'medium': 'small';

  return (
    <Row id="main-feature" gutter={24} className='flex-display'>
      <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 24 }}>
        <div className="block featureBlock bgGray" style={styles.flexDisplay}>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 24 }} className="container-fluid">
            <div className="titleHolder">
              <a className="block-anchor" id="feature" href="#feature">
                Pseudo anchor
              </a>
              <h2>Popular Sports and Free Live Streaming Platforms</h2>
              <p>
                
              </p>
            </div>
            <Row gutter={[12, 12]}>
              {STREAMERS_DATA.map((category, index)=>(
                <Col key={index} xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }}>
                  <div className="list-container" style={{ padding: !matches ? '5px' : '' }}>
                      <List
                          header={
                              <>
                              <div className="list-header">
                                  {/* <img className="sport-image" alt={category?.title} src={category?.imageURL} /> */}
                                  <Typography.Title style={{ margin: '0px', fontSize: matches ? '20px' : '16px', color: '#fff' }}>
                                      {category?.title}
                                  </Typography.Title>
                              </div>
                              <Row style={{display: 'flex', justifyContent: 'space-between'}}>
                                <Typography.Text style={{ margin: '0px', fontSize: matches ? '14px' : '12px', color: 'rgb(214 212 212)' }}>
                                      <strong>Website</strong>
                                  </Typography.Text>
                                  <Typography.Text style={{ margin: '0px', fontSize: matches ? '14px' : '12px', color: 'rgb(214 212 212)' }}>
                                      <strong>Copy Link</strong>
                                  </Typography.Text>
                              </Row>
                              </>
                          }
                          bordered
                          dataSource={category?.streamers}
                          renderItem={(item: any) => {
                              // const [tooltipTitle, setTooltipTitle] = useState('Copy to clipboard');
              
                              // const handleCopy = () => {
                              //     navigator.clipboard.writeText(item?.link)
                              //         .then(() => {
                              //             setTooltipTitle('Copied');
                              //             setTimeout(() => setTooltipTitle('Copy to clipboard'), 2000); // Reset after 2 seconds
                              //         })
                              //         .catch();
                              // };
              
                              return (
                                  <List.Item
                                      actions={[
                                          <a href={item?.link} key="list-loadmore-more" target="_blank" rel="noopener noreferrer">
                                            <ExportOutlined />
                                          </a>,
                                          // <Tooltip title={tooltipTitle} key="copy-tooltip">
                                          //     <Button
                                          //         type="text"
                                          //         icon={<CopyOutlined style={{ color: '#1890ff' }} />}
                                          //         onClick={handleCopy}
                                          //     />
                                          // </Tooltip>
                                      ]}
                                      className="list-item"
                                      style={{ color: '#fff' }}
                                  >
                                      {item?.name}
                                  </List.Item>
                              );
                          }}
                      />
                  </div>
              </Col>             
              ))}
            </Row>
          </Col>
        </div>
      </Col>
      <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 24 }}>
        <div className="block featureBlock bgGray" style={styles.flexDisplay}>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 24 }} className="container-fluid">
            <div className="titleHolder">
              <h2>Know More About our Live Streaming Platforms</h2>
              <p>
                
              </p>
            </div>
            <Row gutter={[12, 12]} style={styles.flexDisplay}>
                  <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 22 }}>
                    <div style={styles.streamersContainer}>
                      <div style={styles.innerContainer}>
                        <Typography.Title 
                        style={{...styles.streamerTitle,fontSize: matches ? '24px':'16px'}}
                        >1. Platforms offer best NFL Streams
                        </Typography.Title>
                        <Typography.Paragraph
                          style={{...styles.streamerParagraph, fontSize: fontSize}}
                        >
                          <strong style={styles.innerTitle}>Streameast App</strong> specializes in NFL live streaming. The total number of user monthly visit this website are in million, around 20 million, and 99% of the users are from the United States. The reason it excels in NFL streams is that, it is based in US and the sport it drives traffic for is most popular sports in the US. According to online traffic analyzing platform like Semrush, Adrefs, this website has than 25K other websites providing backlinks to this website. It also covers other important streams as well like NBA Streams, Soccer Streams, NHL, MLB, Boxing, UFC and many more.
                        </Typography.Paragraph>

                        <Typography.Paragraph
                          style={{...styles.streamerParagraph, fontSize: fontSize}}
                        >
                          <strong style={styles.innerTitle}>Streameast To</strong> is also among the best streaming platforms when it comes to serving NFL live streams. Its huge number of audience and user’s trust on the platform provides it a recognition and millions of user visit this platform to enjoy free live NFL. In addition to this, this Streameast site allow minimal ads to provide a better user experience. Other than NFL, you can find almost every famous sport and enjoy free live streams.
                        </Typography.Paragraph>
                      </div>
                      <Divider></Divider>
                      <div style={styles.innerContainer}>
                        <Typography.Title 
                        style={{...styles.streamerTitle, fontSize: matches ? '24px':'16px'}}
                        >2. Following are best at Soccer Streams and Other Sports Streams </Typography.Title>
                        <Typography.Paragraph
                          style={{...styles.streamerParagraph, fontSize: fontSize}}
                        >
                          <strong style={styles.innerTitle}>Sportsfeed24 </strong> is comparatively new but its quality of service and number of streaming option give it a reputation in free live streaming service available online. What make sportsfeed24 great is that, it not only provides stream link like streameast To and other majority platforms, it provides streams on the same platform. You don’t need to click a link and navigate to another page and watch the match, rather it provide multiple servers to serve you the live match stream which best works for you. It also provide dozens of links for each match, which you can follow and watch the match.
                        </Typography.Paragraph>

                        <Typography.Paragraph
                          style={{...styles.streamerParagraph, fontSize: fontSize}}
                        >
                          Once you are on this platform, you will always be served because this platform offer you multiple options in number of streaming servers and also links to other third party streamers.                         
                          </Typography.Paragraph>

                          <Typography.Paragraph
                          style={{...styles.streamerParagraph, fontSize: fontSize}}
                        >
                          <strong style={styles.innerTitle}>Sportsfeed24 To: </strong>In addition to main sportsfeed24, there is another branch of this platform with same domain but a different domain extension, that is Sportsfeed24[dot].to. This platform is new and it’s arguably the best in business because it has no ads. It’s just being developed, and for good user experience, there are no or minimal ads the user have to go through. Therefore, user enjoy watch their favorite sport on this platform.
                          </Typography.Paragraph>

                          <Typography.Paragraph
                          style={{...styles.streamerParagraph, fontSize: fontSize}}
                        >
                          <strong style={styles.innerTitle}>Streameast Soccer:</strong> Another platform similar to sportsfeed24, which offer almost every popular sport, is Streameast Soccer. This platform is favorite among user in US and United Kingdom. It has its own streams and provides multiple servers to stream from. In addition, it provides links to other streams as well if in some cases the servers do not work, which is very rare.
                          </Typography.Paragraph>

                          <Typography.Paragraph
                          style={{...styles.streamerParagraph, fontSize: fontSize}}
                        >
                          <strong style={styles.innerTitle}>SoccerStreams 100</strong> is among the most popular websites for online sports streaming particularly for soccer. Soccer is a sport that is enjoyed by everyone in all parts of the world. While SoccerStreams 100 primarily streams Soccer, it also streams other famous sports including basketball, the NFL, mixed martial arts, and other most enjoyed live sports. It is an online sports platform that provides live streams of worldwide trending sports at your door without annoying ads and continuous advertisements. The name of this website reflects its popularity.
                          </Typography.Paragraph>
                          <Typography.Paragraph
                          style={{...styles.streamerParagraph, fontSize: fontSize}}
                        >
                          It streams 100 football matches per day ranging from EPL, EFL, English League championship, SERIE A, EURO CUP, COPA DEL REY, and even womens football league divisions. It also broadcasts Premier League matches. You can even access the statistics of the matches and all related information on the website. It is a user-friendly sports streaming website, where you can enjoy and watch all of your favorite sports on your computer. Due to its user outreach and service, it is also considered as a backup for Reddit Soccer Streams.
                          </Typography.Paragraph>

                          <Typography.Paragraph
                          style={{...styles.streamerParagraph, fontSize: fontSize}}
                        >
                          <strong style={styles.innerTitle}>SportSurge</strong>  is an online sports streaming website that attracts users because of its customer service and user satisfaction. Sports fans usually get frustrated by the ads and advertisements in the middle for their favorite sports live streaming. SportSureg solves that problem for users as it does not have any popups and ads.
                          </Typography.Paragraph>
                          <Typography.Paragraph
                          style={{...styles.streamerParagraph, fontSize: fontSize}}
                        >
                          Users can watch live streams of the most famous sports in the world without any disturbance. It shows live streams of sports from soccer to baseball, Hockey, Boxing, and many more. It streams the most enjoyed and popular sports such as the NFL, NBA, F1, and Premier League.
                          </Typography.Paragraph>

                        <Typography.Paragraph
                          style={{...styles.streamerParagraph, fontSize: fontSize}}
                        >
                          <strong style={styles.innerTitle}>Crack Streams</strong> is a free sports website that helps sports fans watch uninterrupted live streams of their favorite sports without worrying about having to pay or register.  It provides all sports live streams in one platform rather than searching for multiple sites and subscriptions. Users can access Crack Streams on any device at hand from mobile phones to laptops. 
                          </Typography.Paragraph>
                          <Typography.Paragraph
                          style={{...styles.streamerParagraph, fontSize: fontSize}}
                        >
                          It is free of charge for everyone with minimum to no ads like many other streaming websites. You can easily scroll through the website to find your favorite sports and watch the live streams without spending time finding different sites for each sport.
                          </Typography.Paragraph>

                        <Typography.Paragraph
                          style={{...styles.streamerParagraph, fontSize: fontSize}}
                        >
                          <strong style={styles.innerTitle}>Footybite</strong> is also called the new home of Reddit Soccer Streams. It was created with the thought of having a backup of the original soccer streams sub at Reddit. Users enjoy the same quality and service of the live streams in Footybite as they did in the original Soccer Streams. The unique feature of Footybite is that the live streams are ranked based on user votes such that the higher the user votes the better the stream will be and ranked high so that users can easily find and watch that stream.  
                          </Typography.Paragraph>
                          <Typography.Paragraph
                          style={{...styles.streamerParagraph, fontSize: fontSize}}
                        >
                          Any match can be accessed on the home page of Footybite; all upcoming match schedules are provided there and links for all matches are present. Users can access the live stream by clicking the link for their favorite sports. Multiple links would be available for each match on the day of streaming depending on the rank and profile of the match. 
                          </Typography.Paragraph>

                          <Typography.Paragraph
                          style={{...styles.streamerParagraph, fontSize: fontSize}}
                        >
                          Footybite is one of the few live sports platforms that streams the most popular matches for free such as Boxing, Formula 1, UFC, and NBA. These matches are enjoyed by sports fans worldwide and Footybite helps sports enthusiasts by providing live streams of such matches with zero cost. Enjoy the live stream of your favorite sport at Footybite.
                          </Typography.Paragraph>
                      </div>
                    </div>
                </Col>              
            </Row>
          </Col>
        </div>
      </Col>  
    </Row>  
  );
};
