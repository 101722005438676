import React, { useEffect, useRef, useState } from 'react';
import {
  Col,
  Divider,
  List,
  Row,
  Spin,
  Tooltip,
  Typography,
} from 'antd';
import { useMediaQuery } from '../../hooks/MediaQuery.hook';
import { styles } from '../../styles';
import { Link } from 'react-router-dom';
import { getBlogs } from '../../apis/fixture.api';
import { formatRelativeTime } from '../../utils/formatTime';


const scrollBarCSS = `
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  ::-webkit-scrollbar-track {
    background: #f4f4f4;
  }
`;

interface Blog {
  _id: string;
  title: string;
  datetime: string; // Use `Date` if you're handling it as a Date object in your app
  id: string;
  image: string;
  category: string;
}

// AutoScrollList Component for Breaking News
const AutoScrollList = ({ blogs }: { blogs: Blog[] }) => {
  const scrollRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const scrollContainer = scrollRef.current;
    if (!scrollContainer) return;

    const interval = setInterval(() => {
      if (scrollContainer.scrollTop + scrollContainer.clientHeight >= scrollContainer.scrollHeight) {
        scrollContainer.scrollTop = 0; // Reset to top
      } else {
        scrollContainer.scrollTop += 1; // Adjust speed
      }
    }, 50);

    return () => clearInterval(interval); // Cleanup
  }, []);

  return (
    <div style={styles.scrollStyle} ref={scrollRef}>
      <style>{scrollBarCSS}</style>
      <List
        dataSource={blogs}
        renderItem={(item) => (
          <List.Item style={{ padding: '10px' }}>
            <Link to={`/blogs/${encodeURIComponent(item?.title)}`}>
              <p style={{ ...styles.blogCategory, fontSize: '11px' }}>
                {item?.datetime}
              </p>
              <Typography.Text
                style={{ fontSize: '18px', fontFamily: 'math', color:'rgb(230, 230, 230)' }}
                strong
              >
                {item.title}
              </Typography.Text>
            </Link>
          </List.Item>
        )}
        bordered
      />
    </div>
  );
};

export const Home = () => {
  const matches = useMediaQuery('(min-width: 768px)');
  const [blogs, setBlogs] = useState<Blog[]>([]);
  const [breakingNews, setBreakingNews] = useState<Blog[]>([]);
  const [loader, setLoader] = useState<boolean>(false);

  const getAllBlogs = async ()=> {
    setLoader(true);

    const response = await getBlogs();
    const breaking = response?.data.map((item: Blog)=>{
      return {
        ...item,
        datetime: formatRelativeTime(item?.datetime)
      }
    })
    setBlogs(response.data);
    setBreakingNews(breaking);
    setLoader(false);
  }

  useEffect(() => {
    getAllBlogs();
  }, []);

  return (
    <>
      <div id='home' className='homeBlock'>
        <Row gutter={24}>
          {matches?<Col span={16}>
          <div style={{textAlign: 'center',padding: '0px',color:'#fff'}}>
              <Typography.Title level={1} style={{color:'#fff'}}>HOTTEST FEED</Typography.Title>
            </div>
            <Row>
              <Col span={18}>
                  <Row>
                    <Link to={`/blogs/${encodeURIComponent(blogs[21]?.title)}`}>
                      <img style={{...styles.mainBlogImage, paddingRight: '10px'}} src={blogs[0]?.image} alt={blogs[0]?.category} />
                      <div className='slider-para'>
                        <p style={styles.slidePostDescription}>{blogs[0]?.title}</p>
                      </div>
                    </Link>
                  </Row>
                  {/* <Carousel dotPosition='bottom' autoplay>
                    {blogs?.slice(20, 30).map((item: Blog, index: number) => {
                      return (
                        <Row key={index}>
                          <Link to={`/blogs/${encodeURIComponent(item?.title)}`}>
                            <img style={{...styles.mainBlogImage, paddingRight: '10px'}} src={item.image} alt={item?.title} />
                            <div className='slider-para'>
                              <p style={styles.slidePostDescription}>{item.title}</p>
                            </div>
                          </Link>
                        </Row>
                      );
                    })}
                  </Carousel> */}
              </Col>
              <Col span={6} style={{maxHeight:'440px'}}>
                {blogs?.slice(30,33).map((item: Blog, index: number) => {
                  return (
                    <Row key={index} gutter={24}>
                      <Link to={`/blogs/${encodeURIComponent(item?.title)}`}>
                        <Tooltip placement='right' 
                        title={
                          <div>
                            <p style={{...styles.blogCategory, fontSize: '11px'}}>{item?.datetime ? new Intl.DateTimeFormat('en-US', {
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric',
                              hour: '2-digit',
                              minute: '2-digit',
                              second: '2-digit',
                              hour12: true
                            }).format(new Date(item?.datetime)):''}
                            </p>
                            <Divider style={{margin: '1px'}}></Divider>
                            <Typography.Text 
                            style={{fontSize: matches ? '16px': '8px', color:'#fff', fontFamily: 'cursive'}}
                            >
                              {item?.title}
                            </Typography.Text>
                          </div>}>
                          {item?.image && <img style={styles.blogImage} src={item?.image} alt={item?.category} />}
                        </Tooltip>
                      </Link>
                    </Row>
                  );
                })}
              </Col>
            </Row>
          </Col>:<></>}
          <Col span={matches ? 6: 24} style={{...styles.breakingNews}}>
            <div style={{textAlign: 'center', paddingTop: '10px'}}>
              <Typography.Title level={2} className='blink'><span style={{color: '#400303'}}>BREAKING NEWS</span></Typography.Title>
            </div>
            <AutoScrollList blogs={breakingNews} />
          </Col>
        </Row>
      </div>
      <div style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 10,
              minHeight: '425px'
            }}>
            {loader ? <Spin /> : <></>}
      </div>
    </>
  );
};